import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectField } from '@application/components/select-field';
import { UserContext } from '@application/context';
import { AccountUser, UserRole } from '@domain/graphql.types';

type SelectRoleProp = {
  accountUser: AccountUser;
  updateRole: (role: UserRole) => void;
};

const SelectRole = ({ accountUser, updateRole }: SelectRoleProp) => {
  const { t: tGlobal } = useTranslation();
  const { user, accountUser: currentAccountUser } = useContext(UserContext);

  const options = useMemo(() => {
    const roles: { value: UserRole; label: string }[] = [
      { value: UserRole.Member, label: tGlobal('userRole.member') },
      { value: UserRole.Admin, label: tGlobal('userRole.admin') },
    ];
    if (currentAccountUser?.role === UserRole.Owner) {
      roles.push({ value: UserRole.Owner, label: tGlobal('userRole.owner') });
    }
    if (
      currentAccountUser?.accountId ===
        '4788875e-f654-461b-aed3-fe67c32f340a' ||
      import.meta.env.MODE !== 'production'
    ) {
      roles.push({
        value: UserRole.SuperAdmin,
        label: tGlobal('userRole.superAdmin'),
      });
    }
    return roles;
  }, [tGlobal, currentAccountUser?.role, currentAccountUser?.accountId]);

  const roleValue = useMemo(
    () =>
      accountUser.role === UserRole.Owner
        ? {
            value: UserRole.Owner,
            label: tGlobal('userRole.owner'),
          }
        : options.find((option) => option.value === accountUser.role),
    [accountUser.role, options, tGlobal]
  );

  // FIXME: overflow error on menuPlacement
  return (
    <SelectField
      hideLabel
      name="role"
      value={roleValue}
      options={options}
      onChange={(option: any) => updateRole(option.value)}
      disabled={
        accountUser.role === UserRole.Owner || accountUser.user?.id === user?.id
      }
    />
  );
};

export default SelectRole;

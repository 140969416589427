import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { Badge, BadgeList } from '@application/components/badge';
import { ButtonLink } from '@application/components/links';
import { CandidateRequestTypeBadge } from '@application/components/request-type-badges';
import { PrivatePage } from '@application/enums/pagesUrl';
import { usePagination } from '@application/hooks';
import { mapDescriptions } from '@application/utils';
import {
  CandidateOpportunitiesFilters,
  CandidateOpportunity,
  CandidateRequestsSortBy,
  CandidateRequestsSortDirective,
  OperationTerritory,
  SortDirection,
} from '@domain/graphql.types';
import { flattenEdges } from '@utils/data-utils';
import {
  getDiffFromNow,
  getHoursSince,
  isOverDeadline,
} from '@utils/date-utils';
import { getLocalizedDescription } from '@utils/i18n-utils';

import useGetCandidateOpportunities from './useGetCandidateOpportunities';

const columnIds = {
  jobSpecialty: 'jobSpecialty',
  experience: 'experience',
  jobAvailability: 'jobAvailability',
  type: 'type',
  operationTerritories: 'operationTerritories',
  receivingOfferDeadline: 'receivingOfferDeadline',
  action: 'action',
  createdAt: 'createdAt',
} as const;

const columnHelper = createColumnHelper<CandidateOpportunity>();

const getFormattedDate = (
  date: string,
  tGlobal: TFunction<'translation', undefined>
) => {
  const result = getDiffFromNow(date);
  if (getDiffFromNow(date)?.length === 0) {
    return tGlobal(`date.today`);
  }
  return result
    ?.map((d) =>
      tGlobal(`date.options.${d.unit}`, {
        count: d.value,
      })
    )
    .join(' ');
};

const getColumns = (
  language: string,
  t: TFunction<'translation', undefined>,
  tGlobal: TFunction<'translation', undefined>
) => {
  const columns: ColumnDef<CandidateOpportunity, any>[] = [
    columnHelper.accessor(
      (row) =>
        getLocalizedDescription(row.jobSpecialty?.descriptions, language),
      {
        id: columnIds.jobSpecialty,
        cell: (info) => (
          <div>
            {getHoursSince(info.row.original.createdAt) < 72 ? (
              <Badge value={t('column.new')} modifier="badge-info" />
            ) : undefined}
            <Link
              to={generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_DETAILS, {
                id: info.row.original.id,
              })}
              className="hover:underline ml-s-4"
            >
              <span>{info.getValue() || '-'}</span>
            </Link>
          </div>
        ),
        header: () => t('column.job'),
        size: 320,
        minSize: 320,
        maxSize: undefined,
        enableSorting: false,
      }
    ),
    columnHelper.accessor((row) => row.experience, {
      id: columnIds.experience,
      cell: (info) => (
        <span>
          {info.getValue()
            ? t('experience.yearsCount', { count: info.getValue() })
            : '-'}
        </span>
      ),
      header: () => t('column.experience'),
      size: 130,
      minSize: 130,
      maxSize: 130,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: columnIds.jobAvailability,
      cell: ({ row }) => {
        let availability = '-';

        if (
          row.original.fullTimeAvailability &&
          row.original.partTimeAvailability
        ) {
          availability = t('column.fullAndPartTime');
        } else if (row.original.fullTimeAvailability) {
          availability = t('column.fullTime');
        } else if (row.original.partTimeAvailability) {
          availability = t('column.partTime');
        }
        return <span>{availability}</span>;
      },
      header: () => t('column.availability'),
      size: 272,
      minSize: 272,
      maxSize: undefined,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.type, {
      id: columnIds.type,
      cell: (info) => <CandidateRequestTypeBadge type={info.getValue()} />,
      header: () => t('column.type'),
      size: 88,
      minSize: 88,
      maxSize: 88,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.operationTerritories, {
      id: columnIds.operationTerritories,
      cell: (info) => {
        const operationTerritories = mapDescriptions(
          info
            .getValue()
            ?.map((territory: OperationTerritory) => territory.descriptions) ||
            [],
          language
        );

        return <BadgeList values={operationTerritories} />;
      },
      header: () => t('column.operationTerritories'),
      size: 272,
      minSize: 272,
      maxSize: undefined,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.receivingOfferDeadline, {
      id: columnIds.receivingOfferDeadline,
      cell: (info) => {
        if (!info.getValue()) return '-';
        return (
          <span>
            {isOverDeadline(
              info.getValue().toString(),
              DateTime.now().toString()
            )
              ? tGlobal('date.isOverDeadline')
              : getFormattedDate(info.getValue(), tGlobal)}
          </span>
        );
      },
      header: () => t('column.deadline'),
      size: 130,
      minSize: 130,
      maxSize: undefined,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: columnIds.action,
      cell: (info) => (
        <ButtonLink
          to={generatePath(PrivatePage.CANDIDATE_OPPORTUNITY_OFFER_CREATE, {
            id: info.row.original.id,
          })}
          size="x-small"
          className="animate-none py-s-0 whitespace-nowrap mr-s-16"
        >
          {t('actions.makeOffer')}
        </ButtonLink>
      ),
      header: () => null,
      enableSorting: false,
      size: 180,
      minSize: 180,
      maxSize: 180,
    }),
  ];

  return columns;
};

type UseCandidateOpportunitiesListProps = {
  columnIds: typeof columnIds;
  columns: ColumnDef<CandidateOpportunity>[];
  count?: number;
  currentPage?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onPaginationReset?: () => void;
  onSortingChange: OnChangeFn<SortingState>;
  sorting: SortingState;
  candidateOpportunities: CandidateOpportunity[];
  isLoading: boolean;
};

const mapSorting = (sorting: SortingState): CandidateRequestsSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: CandidateRequestsSortBy.Date,
  }));

export const useCandidateOpportunitiesList = (
  filters?: CandidateOpportunitiesFilters
): UseCandidateOpportunitiesListProps => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: columnIds.createdAt, desc: true },
  ]);
  const { t, i18n } = useTranslation('candidates', { keyPrefix: 'list' });
  const { t: tGlobal } = useTranslation();

  const {
    pagination,
    currentPage,
    handlePaginationChange,
    setCursors,
    resetPagination,
  } = usePagination();

  const {
    viewModel: { data, isLoading },
  } = useGetCandidateOpportunities(mapSorting(sorting), filters, pagination);

  useEffect(() => {
    setCursors({
      startCursor:
        data?.candidateOpportunities.page.pageInfo?.startCursor || undefined,
      endCursor:
        data?.candidateOpportunities.page.pageInfo?.endCursor || undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.candidateOpportunities.page.pageInfo]);

  useEffect(() => {
    resetPagination();
  }, [sorting, resetPagination]);

  const columns = getColumns(i18n.language, t, tGlobal);

  return {
    columnIds,
    columns: columns as ColumnDef<CandidateOpportunity>[],
    count: data?.candidateOpportunities.pageData?.count,
    currentPage,
    onPaginationChange: handlePaginationChange,
    onPaginationReset: resetPagination,
    onSortingChange: setSorting,
    candidateOpportunities: flattenEdges(
      data?.candidateOpportunities.page.edges?.slice() || []
    ),
    sorting,
    isLoading,
  };
};

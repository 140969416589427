import { useContext } from 'react';

import { AccountContext } from '@application/context';
import { AccountBillingSubscriptionType } from '@domain/graphql.types';

const usePremiumPublishCandidateOfferValidation = () => {
  const { account } = useContext(AccountContext);

  const canPublishCandidateOffer = !(
    account?.subscriptionType ===
      AccountBillingSubscriptionType.EnterprisePremium &&
    account?.enterpriseSubscriptionInformations !== undefined &&
    account?.enterpriseSubscriptionInformations
      ?.premiumRemainingCandidateOffers === 0
  );

  return {
    canPublishCandidateOffer,
    allowedCandidateOffers:
      account?.enterpriseSubscriptionInformations
        ?.premiumAllowedCandidateOffers,
  };
};

export default usePremiumPublishCandidateOfferValidation;

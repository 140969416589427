import { useMemo } from 'react';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormActions, PagePanel } from '@application/components';
import { RootPrivatePage } from '@application/enums/pagesUrl';
import {
  useAccountSubscriptionType,
  usePremiumPublishCandidateOfferValidation,
} from '@application/views/billing';
import {
  AccountBillingSubscriptionType,
  CandidateOpportunity,
  CandidateRequestType,
} from '@domain/graphql.types';

import { CandidateOfferFormFields } from '../../schema';
import OutsourcingFields from './OutsourcingFields';
import RecruitmentFields from './RecruitmentFields';
import TemporaryPlacementFields from './TemporaryPlacementFields';

type AgencyPanelProps = {
  candidateOpportunity?: CandidateOpportunity;
  control: Control<CandidateOfferFormFields, any>;
  errors: FieldErrors<CandidateOfferFormFields>;
  onPublish?: () => void;
  onSave?: () => void;
  register: UseFormRegister<CandidateOfferFormFields>;
  setValue: UseFormSetValue<CandidateOfferFormFields>;
  trigger: UseFormTrigger<CandidateOfferFormFields>;
  watch: UseFormWatch<CandidateOfferFormFields>;
};

const AgencyPanel = ({
  candidateOpportunity,
  control,
  errors,
  onPublish,
  onSave,
  register,
  setValue,
  trigger,
  watch,
}: AgencyPanelProps) => {
  const { t } = useTranslation('candidates');

  const { subscriptionType } = useAccountSubscriptionType();

  const { canPublishCandidateOffer, allowedCandidateOffers } =
    usePremiumPublishCandidateOfferValidation();

  const publishDisabled =
    subscriptionType === AccountBillingSubscriptionType.EnterpriseFreemium ||
    !canPublishCandidateOffer;

  const publishToolTip = useMemo(() => {
    if (
      subscriptionType === AccountBillingSubscriptionType.EnterpriseFreemium
    ) {
      return t('labels.freemiumRestricted');
    }
    if (!canPublishCandidateOffer) {
      return t('labels.premiumLimitReached', { allowedCandidateOffers });
    }
    return undefined;
  }, [allowedCandidateOffers, canPublishCandidateOffer, subscriptionType, t]);

  return (
    <PagePanel title={t('steps.agency')}>
      {candidateOpportunity?.type === CandidateRequestType.Recruitment && (
        <RecruitmentFields
          candidateOpportunity={candidateOpportunity}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />
      )}

      {candidateOpportunity?.type === CandidateRequestType.Outsourcing && (
        <OutsourcingFields
          candidateOpportunity={candidateOpportunity}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />
      )}

      {candidateOpportunity?.type ===
        CandidateRequestType.TemporaryPlacement && (
        <TemporaryPlacementFields
          candidateOpportunity={candidateOpportunity}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          watch={watch}
        />
      )}

      <FormActions
        cancelPath={RootPrivatePage.CANDIDATE_OPPORTUNITIES}
        isLastStep
        onPublish={onPublish}
        onSave={onSave}
        publishToolTip={publishToolTip}
        publishDisabled={publishDisabled}
      />
    </PagePanel>
  );
};

export default AgencyPanel;

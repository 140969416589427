import {
  ColumnDef,
  createColumnHelper,
  OnChangeFn,
  SortingState,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SwitchField } from '@application/components';
import { IconButton } from '@application/components/buttons';
import { UserRoleGuard } from '@application/components/guards';
import { ConfirmationModal } from '@application/components/modal';
import { UserCell } from '@application/components/user-cell';
import { AlertContext, ModalContext, UserContext } from '@application/context';
import {
  AccountUser,
  AccountUserSortBy,
  AccountUsersSortDirective,
  AccountUserStatus,
  SortDirection,
  UserRole,
} from '@domain/graphql.types';
import { flattenEdges } from '@utils/data-utils';
import { formatDate } from '@utils/date-utils';

import { cn } from '../../../../../utils/lib-utils';
import { SelectRole } from '../components';
import useDeleteAccountUser from './useDeleteAccountUser';
import useGetAccountUsers from './useGetAccountUsers';
import useUpdateAccountUserRole from './useUpdateAccountUserRole';
import useUpdateAccountUserStatus from './useUpdateAccountUserStatus';

const columnIds = {
  name: 'name',
  createdAt: 'createdAt',
  active: 'active',
  role: 'role',
  actions: 'actions',
} as const;

const columnHelper = createColumnHelper<AccountUser>();

const getColumns = (
  tGlobal: TFunction<'translation', undefined>,
  t: TFunction<'translation', undefined>,
  language: string,
  confirmUserDeletion: (id: string) => () => void,
  updateRole: (
    accountUserId: string,
    accountUserName: string
  ) => (role: UserRole) => void,
  handleUserStatusUpdate: (
    id: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void,
  userId: string | undefined
) => [
  columnHelper.accessor((row) => row.user, {
    id: columnIds.name,
    cell: (info) => <UserCell user={info.getValue()} />,
    header: () => <span>{t('employeeName')}</span>,
    size: 350,
    minSize: 350,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.status === AccountUserStatus.Active, {
    id: columnIds.active,
    cell: (info) => (
      <UserRoleGuard
        authorizedUserRoles={[
          UserRole.SuperAdmin,
          UserRole.Owner,
          UserRole.Admin,
        ]}
      >
        <SwitchField
          name={info.row.original.id}
          className={cn('justify-between w-s-120', {
            hidden: info.row.original.role === UserRole.Owner,
          })}
          label={info.getValue() ? t('active') : t('inactive')}
          checked={Boolean(info.getValue())}
          onChange={handleUserStatusUpdate(info.row.original.id)}
        />
      </UserRoleGuard>
    ),
    header: () => t('status'),
    size: 250,
    minSize: 150,
    maxSize: undefined,
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.createdAt, {
    id: columnIds.createdAt,
    cell: (info) => <span>{formatDate(info.getValue(), language)}</span>,
    header: () => <span>{t(columnIds.createdAt)}</span>,
    size: 250,
    minSize: 250,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row, {
    id: columnIds.role,
    cell: (info) => {
      const accountUser = info.getValue();
      return (
        <UserRoleGuard
          authorizedUserRoles={[
            UserRole.SuperAdmin,
            UserRole.Owner,
            UserRole.Admin,
          ]}
        >
          <SelectRole
            accountUser={accountUser}
            updateRole={updateRole(accountUser.id, accountUser.user!.name!)}
          />
        </UserRoleGuard>
      );
    },
    header: () => null,
    size: 250,
    minSize: 250,
    maxSize: undefined,
  }),
  columnHelper.accessor((row) => row.id, {
    id: columnIds.actions,
    cell: ({ getValue, row: { original } }) => (
      <UserRoleGuard
        authorizedUserRoles={[
          UserRole.SuperAdmin,
          UserRole.Owner,
          UserRole.Admin,
        ]}
      >
        <IconButton
          text={tGlobal('button.delete')}
          onClick={confirmUserDeletion(getValue())}
          className={cn('animate-none', {
            hidden:
              original.role === UserRole.Owner || original.user?.id === userId,
          })}
          icon={<i className="ri-delete-bin-line text-20" />}
          outline
          size="small"
        />
      </UserRoleGuard>
    ),
    header: () => null,
    size: 32,
    minSize: 32,
    maxSize: 32,
  }),
];

const stringSortToEnum = (by: string): AccountUserSortBy => {
  switch (by) {
    case columnIds.createdAt:
      return AccountUserSortBy.CreatedAt;
    default:
      return AccountUserSortBy.Name;
  }
};

const mapSorting = (sorting: SortingState): AccountUsersSortDirective[] =>
  sorting.map((s) => ({
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    by: stringSortToEnum(s.id),
  }));

type UseUsersList = {
  columns: ColumnDef<AccountUser>[];
  columnIds: typeof columnIds;
  users: AccountUser[];
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
  isLoading: boolean;
};

export const useUsersList = (): UseUsersList => {
  const { user } = useContext(UserContext);
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: true },
  ]);

  const { t: tGlobal } = useTranslation();
  const { t, i18n } = useTranslation('organization', {
    keyPrefix: 'users.list',
  });

  const {
    viewModel: { data, isLoading },
    refetchAccountUsers,
  } = useGetAccountUsers(mapSorting(sorting), {
    excludedStatus: AccountUserStatus.PendingInvitation,
  });

  const { deleteAccountUser } = useDeleteAccountUser();
  const { updateAccountUserRole } = useUpdateAccountUserRole();

  const { updateAccountUserStatus } = useUpdateAccountUserStatus();

  const { setAlertContent } = useContext(AlertContext);
  const { setModal } = useContext(ModalContext);

  const updateRole = useCallback(
    (accountUserId: string, accountUserName: string) => (role: UserRole) => {
      updateAccountUserRole(
        accountUserId,
        role,
        accountUserName,
        user?.name || user?.email
      );
    },
    [updateAccountUserRole, user]
  );

  const deleteUser = useCallback(
    (accountUserId: string) => async () => {
      setModal(null);

      const result = await deleteAccountUser({ input: { accountUserId } });

      if (result.data?.accountUserDelete.ok) {
        setAlertContent({
          modifier: 'alert-success',
          action: t('actions.deleteMemberSuccess'),
        });

        refetchAccountUsers();
      }
    },
    [deleteAccountUser, refetchAccountUsers, setAlertContent, setModal, t]
  );

  const confirmUserDeletion = useCallback(
    (accountUserId: string) => async () => {
      setModal({
        title: t('actions.deleteMemberTitle'),
        content: (
          <ConfirmationModal
            content={t('actions.deleteMemberMessage')}
            onCancel={() => setModal(null)}
            onConfirm={deleteUser(accountUserId)}
          />
        ),
      });
    },
    [setModal, t, deleteUser]
  );

  const handleUpdateStatus = useCallback(
    (userId: string) => async (event: ChangeEvent<HTMLInputElement>) => {
      await updateAccountUserStatus({
        input: {
          accountUserId: userId,
          newStatus: event.target.checked
            ? AccountUserStatus.Active
            : AccountUserStatus.Inactive,
        },
      });
    },
    [updateAccountUserStatus]
  );

  const columns = getColumns(
    tGlobal,
    t,
    i18n.language,
    confirmUserDeletion,
    updateRole,
    handleUpdateStatus,
    user?.id
  );

  return {
    columns: columns as ColumnDef<AccountUser>[],
    columnIds,
    users: flattenEdges(data?.accountUsers.page.edges?.slice() || []),
    sorting,
    onSortingChange: setSorting,
    isLoading,
  };
};
